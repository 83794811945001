import Header from "../../components/Header";
import Aside from "../../components/layout/Aside";
import { useNavigation } from "../../context/NavigationContext";
import { Outlet } from "@tanstack/react-router";

const MainLayout = () => {
  const { navOpen } = useNavigation();

  return (
    <div className="flex h-full flex-col">
      <Header />
      <div className="relative h-full w-full md:flex">
        <div
          className={`${navOpen ? "block" : "hidden"} md:max-w-1/4 absolute inset-0 z-10 block w-full lg:relative lg:max-w-[350px]`}
        >
          <Aside />
        </div>
        <Outlet />
      </div>
    </div>
  );
};

export default MainLayout;
