import { FC } from "react";

interface InfoBoxProps {
  heading: string;
  body: string;
  className?: string;
}

const InfoBox: FC<InfoBoxProps> = (props) => {
  return (
    <div
      className={`mb-5 rounded-lg border-l-4 border-green-500 bg-green-100 p-4 text-black ${props.className}`}
      role="alert"
    >
      <p className="font-bold">{props.heading}</p>
      <p>{props.body}</p>
    </div>
  );
};

export default InfoBox;
