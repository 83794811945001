// Modal.tsx
import React, { useEffect, useRef } from "react";
import { RxCross2 } from "react-icons/rx";

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  content: string;
};

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, content }) => {
  const modalRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line
  }, []);

  // Function to replace ** with <strong> tags
  const formatContent = (text: string) => {
    const replacedText = text.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");
    return { __html: replacedText };
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div
        ref={modalRef}
        className="w-full max-w-md rounded-lg bg-white p-4 text-black"
      >
        <button
          onClick={onClose}
          className="text float-right text-xl font-bold"
        >
          <RxCross2 />
        </button>
        {/* Use dangerouslySetInnerHTML to render the formatted content */}
        <div
          className="mt-4"
          dangerouslySetInnerHTML={formatContent(content)}
        />
      </div>
    </div>
  );
};

export default Modal;
