import { useEffect, useState } from "react";
import { useWallet } from "@solana/wallet-adapter-react";

import { useBle } from "../../context/BleContext";
import * as ble from "../../services/ble/";

import toast from "react-hot-toast";

export default function FormWallet({
  updateCompletion,
}: {
  updateCompletion: (isCompleted: boolean) => void;
}) {
  const bleApi = useBle();
  const [walletAddress, setWalletAddress] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { publicKey } = useWallet();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    updateCompletion(true);

    const payload = {
      wallet: walletAddress,
    };

    const toastId = toast.loading("Linking wallet to gateway...");
    try {
      setIsSubmitting(true);
      const response = await bleApi.fetch(
        ble.API_INITIALIZE,
        ble.Method.POST,
        payload,
      );
      console.log("Wallet address: ", response);
      if (
        response.status === "EGWTP/1.1 200 OK" &&
        response.payload.initialized === true
      ) {
        toast.remove(toastId);
        toast.success("Wallet linked to gateway successfully");
      } else {
        toast.remove(toastId);
        if (response.status !== "EGWTP/1.1 200 OK") {
          toast.error("Wallet link failed, status: " + response.status);
        } else {
          toast.error(
            "Wallet link failed, initialized: " + response.payload.initialized,
          );
        }
      }
      setIsSubmitting(false);
    } catch (error) {
      toast.remove(toastId);
      setIsSubmitting(false);
      toast.error("Wallet link failed, error: " + error);
      console.log(error);
    }
  };

  useEffect(() => {
    if (publicKey) {
      setWalletAddress(publicKey.toString());
    }
  }, [publicKey]);

  return (
    <form onSubmit={handleSubmit} className="w-full xl:w-[600px]">
      <div className="mb-2 space-y-4">
        <div className="flex flex-col space-y-2">
          <label htmlFor="wallet">Solana Wallet-Address:</label>
          <input
            className="rounded-lg p-2 text-black outline-none"
            id="wallet"
            value={walletAddress}
            placeholder="Enter your wallet address"
            required
            onChange={(e) => setWalletAddress(e.target.value)}
            disabled={isSubmitting}
          />
        </div>
        <button className="btn-green mt-2 w-full" disabled={isSubmitting}>
          Link Wallet
        </button>
      </div>
    </form>
  );
}
