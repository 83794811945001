import React, { useEffect, useState } from 'react';
import * as ble from '../services/ble/';


interface NotificationProps {
  bleApi: ble.Api;
}

interface Notification
{
  id: string;
  type: string;
  timestamp: number;
  message: string;
}

const NotificationsComponent: React.FC<NotificationProps> = ({ bleApi }) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  async function deleteNotification(id: string) {
    console.log("Deleting notification:", id);
    try {
      const response = await bleApi.fetch(ble.API_NOTIFICATION + "/" + id, ble.Method.DELETE, { id });
      console.log(response);
      const newNotifications = notifications.filter(notification => notification.id !== id);
      setNotifications(newNotifications);
    } catch (error) {
      console.error("Failed to delete notification:", error);
    }
  }

  useEffect(() => {
    async function fetchNotifications() {
      console.log("Fetching notifications");
      try {
        const response = await bleApi.fetch(ble.API_NOTIFICATION, ble.Method.GET, {});
        const messageIds = response.payload.ids;
        let newNotifications:Notification[] = [];
        for (const messageId of messageIds) {
          const messageResponse = await bleApi.fetch(ble.API_NOTIFICATION + "/" + messageId, ble.Method.GET, { id: messageId });
          newNotifications.push({id: messageResponse.payload.id, type: messageResponse.payload.type, timestamp: messageResponse.payload.timestamp, message: messageResponse.payload.message});
        }
        setNotifications(newNotifications);
      } catch (error) {
        console.error("Failed to fetch notifications:", error);
      }
    }

    fetchNotifications();
  }, [bleApi]);

  return (
        <ul className="notifications-list w-full" style={{ listStyle: 'none', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {notifications.map((notification:Notification, index) => {
            const iconMap: Record<string, string> = {
              'error': '❌',
              'warning': '⚠️',
              'info': 'ℹ️'
            };
            const icon = iconMap[notification.type] || '';
            const localTimestamp = new Date(notification.timestamp * 1000).toLocaleString();
            return (
              <li key={index} className="notification-item w-full" style={{ listStyle: 'none', display: 'flex', alignItems: 'center' }}>
                <span className="notification-icon">{icon}</span>
                {localTimestamp} - {notification.message}
                <button onClick={() => deleteNotification(notification.id)}>🗑</button>
              </li>
            );
          })}
        </ul>
  );
};

export default NotificationsComponent;