import { FC, useEffect, useState } from "react";
import { useBle } from "../context/BleContext";
import ConnectButton from "../components/layout/ConnectButton";
//import FormWiFi from "../components/form/FormWiFi";
//import FormWallet from "../components/form/FormWallet";
//import FormInverter from "../components/form/FormInverter";
//import Accordion from "../components/Accordion";
import ConnectedInfoBox from "../components/connectedinfobox/ConnectedInfoBox";

import * as ble from "../services/ble/";

// react-icons
// import { CiPlug1, CiTimer, CiCircleCheck } from "react-icons/ci";
import NotificationsComponent from "../components/Notifications";

enum ActiveState {
  Unconnected,
  Normal,
  Notificaion
}

const ConnectGW: FC = () => {
  // useEGW connections
  const bleApi = useBle();
  const [activeState, setActiveState] = useState<ActiveState>(ActiveState.Unconnected);

  // listen for connection changes
  useEffect(() => {
    function observerCallback(m: ble.IManager) {
      console.log("Connection status changed: ", m.isConnected());
      if (m.isConnected()) {
        if (activeState === ActiveState.Unconnected) {
          setActiveState(ActiveState.Normal);
        }
      } else {
        setActiveState(ActiveState.Unconnected);
      }

    }

    bleApi.manager.subscribeToConnectionStatus(observerCallback);

    return () => {
      bleApi.manager.unsubscribeFromConnectionStatus(observerCallback);
    };
  }, [bleApi, activeState]);

  return (
    <div className="flex flex-col h-full w-full justify-center items-center bg-[#1d1d1d] p-2 text-white">
      {activeState !== ActiveState.Unconnected ? (
        <div className="w-full max-w-screen-md">
          {activeState === ActiveState.Normal ? (
            <div className="bg-glass my-12 w-full rounded-lg p-4 shadow-lg">
              <h1 className="text-4xl font-extrabold text-center">Connected to Gateway</h1>
              <button className="btn-back mt-5 mb-5 w-full" onClick={async () => await bleApi.manager.disconnect()}>
                Disconnect
              </button>
              <div className="bg-glass w-full p-4">
                <ConnectedInfoBox bleApi={bleApi} />
              </div>
              <button className="btn-green mt-5 mb-5 w-full" onClick={()=>setActiveState(ActiveState.Notificaion)}>
                Notifications
              </button>
            </div>
          ) : (
            <div className="bg-glass my-12 w-full rounded-lg p-4 shadow-lg">
              <h1>Gateway Notifications</h1>
              <button className="btn-back mt-5 mb-5 w-full" onClick={()=>setActiveState(ActiveState.Normal)}>
                Back
              </button>
              <div className="bg-glass w-full p-4">
                <NotificationsComponent bleApi={bleApi} />
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="w-full max-w-screen-md">
          <div className="bg-glass w-full space-y-8 p-4">
            <div className="text-left">
              <h2 className="text-3xl font-extrabold">Connect to gateway</h2>
              <p className="text-md mt-2">
                Connect to your Energy Gateway.
              </p>
            </div>
            <div className="border-t">
              <p className="p-2 text-red-400">
                Not all platforms have bluetooth enabled by default. Please make
                sure that your bluetooth is enabled before connecting.
              </p>
            </div>
            <div className="mb-8">
              <ConnectButton
                onClick={async () => bleApi.manager.connect()}
              />
            </div>
          </div>
        </div>
      )} 
    </div>
  );
  
  
  
};

export default ConnectGW;
