import { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";

interface Props {
  lat: number;
  lng: number;
  handleClick?: ({ lat, lng }: { lat:number, lng:number }) => void;
}

// Jacob's Mapbox access token
mapboxgl.accessToken =
  "pk.eyJ1IjoiamFjb2ItazI4OSIsImEiOiJjbHNrbnJubjQwNGtwMmtyMDd3cTBmem5nIn0.-D_8MJk7ENckqUh9PD_g2A";

export default function MapBoxMap({ lat, lng, handleClick }: Props) {
  const mapContainer = useRef<HTMLDivElement | null>(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const [zoom] = useState<number>(4);

  useEffect(() => {
    if (map.current) {
      //map.current.setCenter([lng, lat]);
      return;
    }

    map.current = new mapboxgl.Map({
      container: mapContainer.current!,
      style: "mapbox://styles/mapbox/streets-v12",
      center: [lng, lat],
      zoom: zoom,
      minZoom: 2.7,
    });

    map.current.on('click', (event) => {
      const { lng, lat } = event.lngLat;
      if (handleClick) {
        handleClick({ lat, lng });
      }
    });

  }, [lng, lat, zoom, handleClick]);

  return <div ref={mapContainer} className="h-full w-full" />;
}
