import GatewayList from "../GatewayList";
import { Link } from "@tanstack/react-router";
import {
  MapPinIcon,
  HomeIcon,
  BoltIcon,
} from "@heroicons/react/24/solid";
import { FaBluetoothB } from "react-icons/fa6";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { useNavigation } from "../../context/NavigationContext";
import { motion as m } from "framer-motion";

const Links = [
  { to: "/", label: "Home", Icon: HomeIcon },
  { to: "/location", label: "Location", Icon: MapPinIcon },
  { to: "/onboard-gw", label: "Onboard New Gateway", Icon: BoltIcon },
  { to: "/connect-gw", label: "Connect to Gateway", Icon: FaBluetoothB },
];

export default function Aside() {
  const { navOpen, toggled } = useNavigation();

  // Animations
  const variants = {
    open: {
      x: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 300, damping: 30 },
    },
    closed: {
      x: "-100%",
      opacity: 0,
      transition: toggled
        ? { type: "spring", stiffness: 300, damping: 30 }
        : { duration: 0 },
    },
  };

  return (
    <m.aside
      animate={navOpen ? "open" : "closed"}
      initial={toggled ? "closed" : false}
      variants={variants}
      className={`${!navOpen && "lg:hidden"} fixed inset-y-0 left-0 z-40 h-full w-[80%] max-w-sm flex-col overflow-hidden border-r border-gray-600/80 bg-[#1d1d1d] text-white lg:static lg:z-auto lg:block lg:w-full`}
      id="default-sidebar"
      aria-label="Sidebar"
    >
      <div className="mb-2 h-[#100dvh] w-full lg:h-full">
        <div className="overflow-y-auto px-3 py-4 shadow-sm">
          <ul className="flex flex-col items-center justify-around space-y-1 font-medium md:items-start">
            {Links.map(({ to, label, Icon }) => (
              <li key={to} className="w-full">
                <Link
                  activeProps={{
                    className: "bg-green-600 font-bold",
                  }}
                  to={to}
                  className="group flex items-center rounded-lg p-2"
                >
                  <Icon className="h-6 w-6 fill-current" />
                  <span className="ml-2">{label}</span>
                </Link>
              </li>
            ))}
          </ul>
          <div className="my-4 border-t border-gray-600/80" />
          <div className="w-full">
            <GatewayList />
          </div>
        </div>
      </div>
      <div className="block p-4 lg:hidden">
        <WalletMultiButton />
      </div>
    </m.aside>
  );
}
