import { useState, useEffect, useRef } from "react";

const PopupModule = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [termsChecked, setTermsChecked] = useState(false);
  const [privacyChecked, setPrivacyChecked] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    const hasAccepted = localStorage.getItem("hasAcceptedTerms");
    if (!hasAccepted) {
      setIsVisible(true);
    }
  }, []);

  useEffect(() => {
    setIsButtonDisabled(!(termsChecked && privacyChecked));
  }, [termsChecked, privacyChecked]);

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const target = contentRef.current;
    if (target) {
      const { scrollTop, clientHeight, scrollHeight } = target;
      if (scrollTop + clientHeight >= scrollHeight) {
        setTermsChecked(true);
        setPrivacyChecked(true);
      }
    }
  };

  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    checkboxType: string,
  ) => {
    const isChecked = e.target.checked;
    if (checkboxType === "terms") {
      setTermsChecked(isChecked);
    } else if (checkboxType === "privacy") {
      setPrivacyChecked(isChecked);
    }
  };

  const handleAccept = () => {
    localStorage.setItem("hasAcceptedTerms", "true");
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-80 p-4">
      <div className="max-h-[500px] max-w-[500px] overflow-x-hidden rounded-md bg-white p-6 shadow-xl">
        <div
          onScroll={handleScroll}
          ref={contentRef}
          className="h-auto space-y-4 overflow-auto"
        >
          By proceeding, you acknowledge that you have reviewed and consent to
          our{" "}
          <a
            className="text-blue-500 underline underline-offset-2 hover:text-blue-700"
            href="https://docs.srcful.io/srcful-terms/terms"
            target="_blank"
            rel="noreferrer"
          >
            Terms of Service
          </a>{" "}
          and
          <a
            className="text-blue-500 underline underline-offset-2 hover:text-blue-700"
            href="https://docs.srcful.io/srcful-terms/privacy"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            Privacy Policy,
          </a>{" "}
          which cover the ways we collect, use, share, and protect your personal
          information, as well as the limitations of our liability. Your
          agreement is necessary to use our services. I confirm that I am over
          the age of consent in my jurisdiction, have read, and fully agree to
          the Terms of Service and Privacy Policy.
        </div>
        <div className="flex items-start justify-between">
          <div className="flex flex-col">
            <div className="mt-4 flex items-center">
              <input
                type="checkbox"
                checked={termsChecked}
                onChange={(e) => handleCheckboxChange(e, "terms")}
                className="mr-2 h-4 w-4"
              />
              <label className="text-sm">
                I agree to the Terms and Conditions.
              </label>
            </div>
            <div className="mt-4 flex items-center">
              <input
                type="checkbox"
                checked={privacyChecked}
                onChange={(e) => handleCheckboxChange(e, "privacy")}
                className="mr-2 h-4 w-4"
              />
              <label className="text-sm">I agree to the Privacy Policy.</label>
            </div>
          </div>
          <button
            className={`mt-4 rounded px-4 py-2 text-white ${isButtonDisabled ? "cursor-not-allowed bg-gray-300" : "bg-green-500 hover:bg-green-700"}`}
            onClick={handleAccept}
            disabled={isButtonDisabled}
          >
            I Accept
          </button>
        </div>
      </div>
    </div>
  );
};

export default PopupModule;
