import { Suspense } from "react";
import MapBoxMap from "./MapBoxMap"; // Assuming this is your MapBox component

interface MapboxProps {
  lat: number;
  lng: number;
  handleClick?: ({ lat, lng }: { lat: number; lng: number }) => void;
}

const Mapbox: React.FC<MapboxProps> = ({ lat, lng, handleClick }) => {
  return (
    <div className="mt-4 flex h-screen max-h-[1000px] min-h-full w-full flex-col justify-center gap-4 sm:flex-row md:max-h-[500px] md:min-h-fit">
      <div className="bg-glass h-full w-full overflow-hidden">
        <Suspense fallback={<p>Loading...</p>}>
          <MapBoxMap lat={lat} lng={lng} handleClick={handleClick} />
        </Suspense>
      </div>
    </div>
  );
};

export default Mapbox;
