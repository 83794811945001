type ConnectedInfoBoxProps = {
  children?: React.ReactNode;
};

export default function ConnectedInfoBox({ children }: ConnectedInfoBoxProps) {
  return (
    <div className="flex h-full w-full flex-col rounded-lg">
      <div className="flex flex-col gap-4">{children}</div>
    </div>
  );
}
