import { FC, useContext, useState } from "react";
import { createMessageStr, signSrcfulMessage } from "../messaging/Message";
import { useWallet } from "@solana/wallet-adapter-react";
import { useQuery } from "@tanstack/react-query";
import { GatewayContext } from "../context/GatewayContext";
import toast from "react-hot-toast";
import ContentFramework from "../components/ContentFramework";
import Mapbox from "../components/map/MapBox";
import InfoBox from "../components/InfoBox";

const Location: FC = () => {
  const defaultLatitude = 50.2;
  const defaultLongitude = 10.9;

  const [latitude, setLatitude] = useState(defaultLatitude.toString());
  const [longitude, setLongitude] = useState(defaultLongitude.toString());
  const { publicKey, signMessage } = useWallet();
  const { gateway } = useContext(GatewayContext);

  const handleMapClick = ({ lat, lng }: { lat: number; lng: number }) => {
    setLatitude(lat.toFixed(6));
    setLongitude(lng.toFixed(6));
  };

  const { refetch, isFetching } = useQuery({
    queryKey: ["signLocationChange"],
    queryFn: async () => {
      if (!publicKey || !signMessage || !gateway) return;
      const toastId = toast.loading("Waiting for signature...");

      //CREATE MESSAGE
      const message = createMessageStr({
        wallet: publicKey.toString(),
        durationInSec: 60,
        messageData: {
          Latitude: latitude,
          Longitude: longitude,
          "Gateway Serial": gateway.serial,
        },
        Statement:
          "By signing this message, I confirm that the location of the gateway is correct.",
      });

      //SIGN MESSAGE
      const signedMessage = await signSrcfulMessage(signMessage, message);

      if (!signedMessage) {
        toast.remove(toastId);
        return;
      }

      //UPDATE
      const query = JSON.stringify({
        query: `
                mutation($location: LocationInput!) {
                    setLocation(location: $location){
                        success
                    }
                }`,
        variables: {
          location: {
            message: signedMessage.message,
            signature: signedMessage.signature,
          },
        },
      });

      const requestHeaders: HeadersInit = new Headers();
      requestHeaders.set("Content-Type", "application/json");
      requestHeaders.set("Content-Length", query.length.toString());

      toast.loading("Sending data ...", {
        id: toastId,
      });

      const response = await fetch("https://api.srcful.dev/", {
        method: "POST",
        body: query,
        headers: requestHeaders,
      });
      toast.dismiss(toastId);
      toast.success("Successfully saved!"); // TODO: check that its an acctul success...
      return await response.json();
    },
    enabled: false,
  });

  if (!gateway) return null;

  return (
    <ContentFramework>
      <div className="bg-glass p-4">
        <h1 className="mb-10 font-srcful text-4xl">{gateway.name}</h1>

        <InfoBox
          heading="Gateway position"
          body="Please provide the precise latitude and longitude of your DER for accurate verification. Inaccurate location may reduce your rewards. Your exact location is only shared with Srcful and will not appear in the explorer."
        />
        <form
          className="w-full"
          onSubmit={(e) => {
            e.preventDefault();
            refetch();
          }}
        >
          <div className="mb-6 flex flex-wrap">
            <div className="mb-6 w-full md:mb-0 md:w-1/2 md:pr-3">
              <label
                className="mb-2 block text-xs font-bold uppercase tracking-wide text-gray-100 dark:text-white"
                htmlFor="grid-first-name"
              >
                Latitude
              </label>
              <input
                onChange={(e) => setLatitude(e.target.value)}
                value={latitude}
                className="mb-3 block w-full appearance-none rounded border px-4 py-3 leading-tight text-gray-700 focus:border-gray-500 focus:bg-white focus:outline-none"
                id="grid-first-name"
                type="text"
              />
            </div>
            <div className="w-full md:w-1/2 md:pl-3">
              <label
                className="mb-2 block text-xs font-bold uppercase tracking-wide text-gray-100 dark:text-white"
                htmlFor="grid-last-name"
              >
                Longitude
              </label>
              <input
                onChange={(e) => setLongitude(e.target.value)}
                value={longitude}
                className="mb-3 block w-full appearance-none rounded border border-gray-200 px-4 py-3 leading-tight text-gray-700 focus:border-gray-500 focus:bg-white focus:outline-none"
                id="grid-last-name"
                type="text"
              />
            </div>
            <button
              disabled={isFetching}
              type="submit"
              className="ml-auto flex items-center justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {isFetching && (
                <svg
                  className="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              )}
              <span>{isFetching ? "Processing" : "Sign change"}</span>
            </button>
          </div>
        </form>
        <p className="text-center italic text-gray-200">
          We currently do not show your location on the map.
        </p>
        <Mapbox
          lat={parseFloat(latitude)}
          lng={parseFloat(longitude)}
          handleClick={handleMapClick}
        />
      </div>
    </ContentFramework>
  );
};

export default Location;
