import { Method, MethodUtil } from "./methods";

export class EGWTPResponse {
  
  status: string;
  method: Method;
  location: string;
  contentType: string;
  offset: number; // the offset of the data recieved
  contentLength: number;    // now a number
  private _payload: Record<string, any> = {};
  private _buffer: number[] = [];   // to store raw bytes
  private _content: number[] = []; // to store the content bytes

  constructor(rawBytes?: number[]) {
    this.status = '';
    this.method = Method.UNKNOWN;
    this.location = '';
    this.contentType = '';
    this.contentLength = 0; // default
    this.offset = 0;

    if (rawBytes) {
      this.setFromBytes(rawBytes);
    }
  }

  get content(): number[] {
    return this._content;
  }

  public setFromBytes(rawBytes: number[]) {
    this._buffer = rawBytes;

    // Decode headers
    let headersPart = this.getUntilSequence(this._buffer, [13, 10, 13, 10]); // "\r\n\r\n" in ASCII and UTF-8


    if (!headersPart) {
      return;
    }
    this._content = this._buffer.slice(headersPart.length);
    const decoder = new TextDecoder();

    if (this._content.length + headersPart.length !== rawBytes.length) {
      console.log("Error: Content length does not match the raw bytes length");
      console.log("Content length: ", this._content.length);
      console.log("Raw bytes length: ", rawBytes.length);
      console.log("Headers length: ", headersPart.length);
    }

    this.processHeaders(decoder.decode(new Uint8Array(headersPart)));

    if (this._content.length >= this.contentLength && this.contentLength > 0) {
      
      let contentStr = decoder.decode(new Uint8Array(this._content));
      
      try {
        this._payload = JSON.parse(contentStr);
      } catch (err) {
        console.error("Error parsing JSON content: ", err);
        console.error("Content: ", contentStr);
      }
    }
  }

  public cloneWithPayloadFromBytes(rawBytes: number[]): EGWTPResponse {
    // we update the buffer with the new raw bytes
    const headersPart = this.getUntilSequence(this._buffer, [13, 10, 13, 10]); // "\r\n\r\n" in ASCII and UTF-8
    return new EGWTPResponse(headersPart.concat(rawBytes));
  }

  get payload(): Record<string, any> {
    return this._payload;
  }

  private processHeaders(headersStr: string) {
    const headersLines = headersStr
      .split("\n")
      .map((line) => line.trim())
      .filter((line) => line);

    headersLines.forEach(line => {
      if (line.startsWith('EGWTP')) {
        this.status = line;
      } else if (line.startsWith('Location')) {
        this.location = line.split(': ')[1];
      } else if (line.startsWith('Content-Type')) {
        this.contentType = line.split(': ')[1];
      } else if (line.startsWith('Content-Length')) {
        this.contentLength = Number(line.split(': ')[1]);
      } else if (line.startsWith('Method')) {
        this.method = MethodUtil.fromString(line.split(': ')[1]);
      } else if (line.startsWith('Offset')) {
        this.offset = Number(line.split(': ')[1]);
      } else {
        console.log(`Unknown header key found: ${line}`);
      }
    });
  }

  private getUntilSequence(buffer: number[], sequence: number[]): number[] {
    for(let i = 0; i <= buffer.length - sequence.length; i++) {
        let mismatch = sequence.some((val, j) => val !== buffer[i + j]);
        if (!mismatch) {
            // Sequence found, slice and return
            return buffer.slice(0, i + sequence.length);
        }
    }
 
    // Sequence not found in buffer
    return [];
  }
}
