import { useState, useEffect } from "react";
import { motion as m } from "framer-motion";
import { FaBluetoothB } from "react-icons/fa6";
import * as ble from "../../services/ble/";
import { useBle } from "../../context/BleContext";

type ConnectButtonProps = {
  onClick: () => void;
};

type Variants = {
  initial: {
    boxShadow: string[];
    transition: {
      duration: number;
      ease: "easeInOut";
      repeat: number;
      repeatType?: "loop";
    };
  };
  disabled: {
    boxShadow: string[];
    transition: {
      duration: number;
      ease: "easeInOut";
      repeat: number;
      repeatType?: "loop";
    };
  };
  hover: {
    boxShadow: string;
  };
};

const buttonVariants: Variants = {
  initial: {
    boxShadow: [
      "0 0 0 0 rgba(11, 232, 152, 0.7)",
      "0 0 0 10px rgba(11, 232, 152, 0)",
      "0 0 0 0 rgba(11, 232, 152, 0.7)",
    ],
    transition: {
      duration: 3,
      ease: "easeInOut",
      repeat: Infinity,
      repeatType: "loop",
    },
  },
  disabled: {
    boxShadow: [
      "0 0 0 0 rgba(220, 38, 38, 0.7)",
      "0 0 0 10px rgba(220, 38, 38, 0)",
      "0 0 0 0 rgba(220, 38, 38, 0.7)",
    ],
    transition: {
      duration: 3,
      ease: "easeInOut",
      repeat: Infinity,
      repeatType: "loop",
    },
  },
  hover: {
    boxShadow: "0 0 0 0 rgba(11, 232, 152, 0.7)",
  },
};

const ConnectButton: React.FC<ConnectButtonProps> = ({ onClick }) => {

  enum ConnectionStatus {
    CONNECTING,
    CONNECTED,
    DISCONNECTED,
  }

  // useEGW connections
  const bleApi = useBle();
  const [isConnected, setIsConnected] = useState<ConnectionStatus>(ConnectionStatus.DISCONNECTED);
  const [error, setError] = useState<DOMException>();
  const [statusStrings, setStatusStrings] = useState<string[]>([]);

  // listen for connection changes
  useEffect(() => {
    function connectionStatusListener(m: ble.IManager, status: string) {
      console.log("Connection status changed: ", status);
      if (m.isConnected()) {
        setError(undefined);
        setStatusStrings([]);
        setIsConnected(ConnectionStatus.CONNECTED);
      } else if (status.length > 0) {
        setIsConnected(ConnectionStatus.CONNECTING);
        setStatusStrings((prev) => [...prev, status]);
      } else {
        setIsConnected(ConnectionStatus.DISCONNECTED);
      }
    }

    function errorListener(m: ble.IManager, error: DOMException) {
      setError(error);
    }

    bleApi.manager.subscribeToConnectionStatus(connectionStatusListener);
    bleApi.manager.subscribeToExceptions(errorListener);
    

    return () => {
      bleApi.manager.unsubscribeFromConnectionStatus(connectionStatusListener);
      bleApi.manager.unsubscribeFromExceptions(errorListener);
    };
  }, [bleApi, ConnectionStatus.CONNECTED, ConnectionStatus.CONNECTING, ConnectionStatus.DISCONNECTED]);

  const handleConnect = async () => {
    setIsConnected(ConnectionStatus.CONNECTING);
    try {
      setError(undefined);
      console.log("Connecting...");
      await onClick();
    } catch (error: any) {
      setIsConnected(ConnectionStatus.DISCONNECTED);
      if (error instanceof DOMException) {
        
        console.error(`Caught a DOMException: ${error.name}, ${error.message}`);
        setError(error);
        
    } else {
        // Rethrow if it's not a DOMException
        throw error;
    }
    }
  };

  function handleError(error: DOMException) {

    switch (error.message) {
      case "Bluetooth adapter not available.":
        return <>
        <div>
        <p>It seems bluethooth is not enabled on your computer or smartphone.<br/>Some tips for connecting:</p>
        <ul  style={{ listStyleType: 'disc' }}>
          <li>Make sure your computer or phone has bluethooth enabled in the settings.</li>
          <li>Use a browser that supports bluethooth, we reccommend Chrome.</li>
        </ul>
        </div>
        </>
    }
      

    return <>
    <div>
    <p>It seems the gateway could not be found or the connection could not be established.<br/>Some tips for connecting:</p>
    <ul style={{ listStyleType: 'disc' }}>
      <li>Make sure the gateway is powered on.</li>
      <li>Make sure the gateway is close to your computer or phone.</li>
      <li>If your gatway has a button double click the button to enable bluetooth on the gateway.</li>
    </ul>
    </div>
    <div>
    <p>Some additional troubleshooting tips:</p>
    <ul style={{ listStyleType: 'disc' }}>
      <li>Toggling bluethooth off and on on your computer or phone may help.</li>
      <li>Using another browser may help, we reccommend Chrome.</li>
      <li>Reloading the app page may help.</li>
      <li>Rebooting the gateway may help.</li>
      <li>Rebooting your computer may help.</li>
      <li>Try antother device, we reccommend using an Android phone.</li>
    </ul>
    </div>
    </>
  }

  return (
    <div className="flex flex-col items-center justify-center gap-1">
    <m.button
      className="btn-circle flex flex-col items-center justify-center gap-1"
      variants={buttonVariants}
      initial="animate"
      animate={isConnected !== ConnectionStatus.DISCONNECTED ? "disabled" : "initial"}
      whileHover="hover"
      onClick={handleConnect}
      disabled={isConnected === ConnectionStatus.CONNECTING}
    >
      {isConnected !== ConnectionStatus.DISCONNECTED ? "Connecting..." : "Connect"}
      <FaBluetoothB />
    </m.button>
    {error && <div>{handleError(error)}</div>}
    {statusStrings.length > 0 && <div>{statusStrings.map((status, index) => <div key={index}>{status}</div>)}</div>}
    </div>
  );
};

export default ConnectButton;
