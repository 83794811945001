import React, { createContext, useContext, useEffect } from "react";
import * as ble from "../services/ble/";

const BleContext = createContext<ble.Api | null>(null);

type BleProviderProps = {
  children: React.ReactNode;
};

const bleApi = new ble.Api(new ble.Manager());

export function BleProvider({ children }: BleProviderProps) {
  useEffect(() => {
    return () => bleApi.manager.disconnect();
  }, []);

  return <BleContext.Provider value={bleApi}>{children}</BleContext.Provider>;
}

export function useBle() {
  const context = useContext(BleContext);
  if (context === null) {
    throw new Error("useBle must be used within a BleProvider");
  }
  return context;
}
