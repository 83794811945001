import bs58 from "bs58";

export interface Message {
  wallet: string;
  durationInSec: number;
  messageData: Record<string, string>;
  Statement: string;
}

export interface SignedMessage {
  message: string;
  signature: string;
}

export default Message;

export const createMessageStr = (message: Message): string => {
  var issued = new Date(Date.now());
  var expiration = new Date(Date.now());
  expiration.setSeconds(issued.getSeconds() + message.durationInSec);

  let messageStr = `https://srcful.io/ wants you to sign data with your Solana account:\n`;
  messageStr += message.wallet + "\n";
  messageStr += "\n";
  messageStr += message.Statement + "\n";
  messageStr += "\n";

  for (var name in message.messageData) {
    messageStr += `${name}: ${message.messageData[name]}\n`;
  }
  messageStr += `Issued At (UTC): ${issued.toISOString()}\n`;
  messageStr += `Expiration Time (UTC): ${expiration.toISOString()}`;

  return messageStr;
};

export const signSrcfulMessage = async (
  signMessage: (message: Uint8Array) => Promise<Uint8Array>,
  message: string,
): Promise<SignedMessage | null> => {
  try {
    const messageBytes = new TextEncoder().encode(message);
    const signature = await signMessage(messageBytes);
    const signatureb58 = bs58.encode(signature);
    const message58 = bs58.encode(messageBytes);
    return {
      message: message58,
      signature: signatureb58,
    };
  } catch (e) {
    console.log(e);
    return null;
  }
};
