import { FC, useEffect, useState } from "react";
import { useBle } from "../context/BleContext";
import ConnectButton from "../components/layout/ConnectButton";
import FormWiFi from "../components/form/FormWiFi";
import FormWallet from "../components/form/FormWallet";
import FormInverter from "../components/form/FormInverter";
import FormLocation from "../components/form/FormLocation";
import Accordion from "../components/Accordion";
import ConnectedInfoBox from "../components/connectedinfobox/ConnectedInfoBox";

import * as ble from "../services/ble/";

// react-icons
import { CiPlug1, CiTimer, CiCircleCheck } from "react-icons/ci";

const OnboardGW: FC = () => {
  // useEGW connections
  const bleApi = useBle();
  const [isConnected, setIsConnected] = useState<boolean>(false);

  // listen for connection changes
  useEffect(() => {
    function observerCallback(m: ble.IManager) {
      console.log("Connection status changed: ", m.isConnected());
      setIsConnected(m.isConnected());
    }

    bleApi.manager.subscribeToConnectionStatus(observerCallback);

    return () => {
      bleApi.manager.unsubscribeFromConnectionStatus(observerCallback);
    };
  }, [bleApi]);

  const accordionForms = [
    {
      title: "Step 1: Wifi Config",
      content: <FormWiFi updateCompletion={() => {}} />,
    },
    {
      title: "Step 2: Crypto Wallet Config",
      content: <FormWallet updateCompletion={() => {}} />,
    },
    {
      title: "Step 3: Inverter Config",
      content: <FormInverter updateCompletion={() => {}} />,
    },
    {
      title: "Step 4: Location",
      content: <FormLocation />,
    },
  ];

  return (
    <div className="grid h-full w-full grid-cols-1 bg-[#1d1d1d] p-2 text-white lg:place-items-center">
      {isConnected ? (
        <div className="grid place-items-center gap-0 md:grid-cols-2 md:gap-4">
          <div className="bg-glass my-12 w-full rounded-lg p-4 shadow-lg">
            <Accordion items={accordionForms} />
            <button
              className="btn-green mt-10 w-full"
              onClick={async () => await bleApi.manager.disconnect()}
            >
              Disconnect
            </button>
          </div>
          <div className="bg-glass w-full p-4">
            <ConnectedInfoBox bleApi={bleApi}/>
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-1 place-items-center gap-10 text-white md:grid-cols-2 md:gap-0">
          <div className="bg-glass w-full max-w-xl space-y-8 p-4">
            <div className="text-left">
              <h2 className="text-3xl font-extrabold">Getting Started</h2>
              <p className="text-md mt-2">
                Onboard your Energy Gateway to start collecing rewards.
              </p>
            </div>
            <div className="space-y-8">
              <div className="flex items-start space-x-2">
                <CiPlug1 size={28} />
                <div className="space-y-1">
                  <h3 className="text-lg font-medium">
                    Step 1: Activate Your Gateway
                  </h3>
                  <p className="text-sm">
                    Connect your Gateway to power and use an ethernet cable for
                    initial setup. Switch to WiFi later.
                  </p>
                </div>
              </div>
              <div className="flex items-start space-x-2">
                <CiTimer size={25} />
                <div className="space-y-1">
                  <h3 className="text-lg font-medium">
                    Step 2: Firmware Update
                  </h3>
                  <p className="text-sm">
                    Your Gateway will download the latest firmware
                    automatically. This process takes roughly 10 minutes.
                  </p>
                </div>
              </div>
              <div className="flex items-start space-x-2">
                <CiCircleCheck size={25} />
                <div className="space-y-1">
                  <h3 className="text-lg font-medium">Step 3: Gateway Setup</h3>
                  <p className="text-sm">
                    To complete the setup, press <b>Connect</b> and choose your
                    Gateway from the provided list.
                  </p>
                </div>
              </div>
            </div>
            <div className="border-t">
              <p className="p-2 text-red-400">
                Not all platforms have bluetooth enabled by default. Please make
                sure that your bluetooth is enabled before connecting
              </p>
            </div>
          </div>
          <div className="mb-8">
            <ConnectButton
              onClick={async () =>
                setIsConnected(await bleApi.manager.connect())
              }
            />
          </div>
        </div>
      )} 
    </div>
  );
};

export default OnboardGW;
