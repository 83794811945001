import { useWallet } from "@solana/wallet-adapter-react";
import { FC, useContext } from "react";
import { GatewayContext } from "../context/GatewayContext";
import ContentFramework, {
  LightFramework,
} from "../components/ContentFramework";
import { useQuery } from "@tanstack/react-query";
//import MapBox from "../components/map/MapBox";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import Srcful from "../assets/srcful-bg.png";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";

const Index: FC = () => {
  const { connected } = useWallet();
  const { gateway } = useContext(GatewayContext);

  const { data } = useQuery({
    queryKey: ["getGwBasics"],
    queryFn: async () => {
      if (!gateway) return;

      const query = JSON.stringify({
        query: `
          {
            gateway{
              gateway(id:"${gateway?.serial}"){
                h3Index
                name
                id
                wallet
              }
            }
          }`,
      });

      const requestHeaders: HeadersInit = new Headers();
      requestHeaders.set("Content-Type", "application/json");
      requestHeaders.set("Content-Length", query.length.toString());

      const response = await fetch("https://api.srcful.dev/", {
        method: "POST",
        body: query,
        headers: requestHeaders,
      });

      var json = await response.json();
      return json.data.gateway.gateway;
    },
    enabled: !!gateway,
    refetchInterval: 500,
  });

  if (!connected || !gateway || !data)
    return (
      <LightFramework>
        <div className="grid h-full w-full grid-cols-1 place-items-center gap-4 bg-[#1d1d1d] p-4 font-srcful lg:grid-cols-2">
          <div className="bg-glass relative flex h-full w-full overflow-hidden lg:col-span-2">
            <div
              className="absolute inset-0 z-0"
              style={{
                backgroundImage: `url(${Srcful})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                filter:
                  "brightness(10%) saturate(100%) blur(2px) contrast(75%)",
              }}
            ></div>
            <div className="z-10 flex-grow p-4 md:p-8 lg:p-24">
              <h1 className="mb-8 text-4xl font-extrabold text-white md:mb-12 lg:text-7xl">
                Srcful Energy
              </h1>
              <p className="mb-8 text-lg font-semibold text-white lg:mb-14 lg:text-xl">
                Log in with your Solana wallet <WalletMultiButton />
              </p>
              <p className="mb-8 text-lg font-semibold text-white lg:mb-14 lg:text-xl">
                After connecting, proceed by selecting{" "}
                <span className="text-[#00bf63]">Onboard New Gateway</span> from
                the navbar. <br />
                <span className="text-sm text-red-400">
                  Please note that iPhones are currently not supported for onboarding.
                </span>
              </p>
            </div>
          </div>
          <div className="bg-glass flex h-full w-full flex-col p-4 text-white md:p-8 lg:p-12">
            <h2 className="mb-8 flex-1 text-2xl font-semibold md:mb-12 md:text-3xl">
              Setup Guide
            </h2>
            <div className="flex items-center gap-8">
              <a
                href="https://github.com/srcfl/egw-getting-started"
                rel="noreferrer"
                target="_blank"
                className="rounded-lg bg-[#00bf63] p-8"
              >
                <ArrowRightIcon className="h-6 w-6 fill-current" />
              </a>
              <p className="text-lg">Open guide</p>
            </div>
          </div>
          <div className="bg-glass flex h-full w-full flex-col items-start p-4 text-white md:p-8 lg:p-12">
            <h2 className="mb-8 flex-1 text-2xl font-semibold md:mb-12 md:text-3xl">
              Questions?
            </h2>
            <div className="flex items-center gap-8">
              <a
                href="https://discord.gg/PkYumtwY3y"
                rel="noreferrer"
                target="_blank"
                className="rounded-lg bg-[#00bf63] p-8"
              >
                <ArrowRightIcon className="h-6 w-6 fill-current" />
              </a>
              <p className="text-lg">Join our Discord</p>
            </div>
          </div>
        </div>
      </LightFramework>
    );

  return (
    <ContentFramework>
      <div className="my-4 flex w-full flex-col items-center gap-4 md:my-0 lg:flex-row">
        <div className="bg-glass w-full p-4 text-gray-200 lg:w-1/2 dark:text-white">
          <h1 className="mb-10 font-srcful text-4xl font-bold">
            {gateway.name}
          </h1>
          <div className="w-full">
            <div>
              <h2 className="text-base font-bold leading-7">
                Energy Gateway Information
              </h2>
            </div>
            <div className="mt-5">
              <dl className="divide-y divide-gray-200">
                <div className="py-5 md:flex md:justify-between md:pr-4">
                  <dt className="text-md font-semibold leading-6">Name</dt>
                  <dd className="text-md mt-1 leading-6 sm:col-span-2 sm:mt-0">
                    {data.name}
                  </dd>
                </div>
                <div className="py-5 md:flex md:justify-between md:pr-4">
                  <dt className="text-md font-semibold leading-6">
                    Serial / Id
                  </dt>
                  <dd className="text-md mt-1 leading-6 sm:col-span-2 sm:mt-0">
                    {data.id}
                  </dd>
                </div>
                <div className="py-5 md:flex md:justify-between md:pr-4">
                  <dt className="text-md font-semibold leading-6">
                    Wallet publickey
                  </dt>
                  <dd className="text-md mt-1 truncate leading-6 sm:col-span-2 sm:mt-0">
                    {data.wallet}
                  </dd>
                </div>
                <div className="py-5 md:flex md:justify-between md:pr-4">
                  <dt className="text-md font-semibold leading-6">H3-Index</dt>
                  <dd className="mt-1 text-sm leading-6 sm:col-span-2 sm:mt-0">
                    {data.h3Index}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </ContentFramework>
  );
};

export default Index;
