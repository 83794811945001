import { FC, useContext, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { useWallet } from "@solana/wallet-adapter-react";
import { Gateway, GatewayContext } from "../context/GatewayContext";
import { FiHexagon } from "react-icons/fi";

const GatewayList: FC = () => {
  const { connected, publicKey } = useWallet();
  const navigate = useNavigate();
  const { data } = useQuery({
    queryKey: ["walletGateways"],
    queryFn: async () => {
      const query = JSON.stringify({
        query: `{
                gateway{
                    gateways(wallet:"${publicKey?.toString()}", take:100, showTestMode: true){
                        name
                        id
                }}}`,
      });

      const requestHeaders: HeadersInit = new Headers();
      requestHeaders.set("Content-Type", "application/json");
      requestHeaders.set("Content-Length", query.length.toString());

      const response = await fetch("https://api.srcful.dev/", {
        method: "POST",
        body: query,
        headers: requestHeaders,
      });
      const json = await response.json();
      return json.data.gateway.gateways.map((f: any): Gateway => {
        return { name: f.name, serial: f.id };
      });
    },
    enabled: !!publicKey,
  });

  const { setGateway } = useContext(GatewayContext);

  useEffect(() => {
    if (!data || !connected) return;
    if (data.length === 1) {
      setGateway(data[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, connected]);

  return (
    <div className="flex w-full items-center text-white">
      {connected && data && data.length > 0 && (
        <div className="flex flex-col p-2">
          <h2 className="my-4 font-srcful text-xl font-semibold">Gateways</h2>
          <ul className="list-none font-srcful font-semibold">
            {data.map((gateway: Gateway) => (
              <li
                className="mb-4 ml-2 flex cursor-pointer items-center gap-x-2"
                key={gateway.serial}
                onClick={() => {
                  setGateway(gateway);
                  navigate({ to: "/" });
                }}
              >
                <FiHexagon size={25} />
                {gateway.name}
              </li>
            ))}
          </ul>
        </div>
      )}
      {connected && data && data.length === 0 && (
        <div
          className="relative rounded border border-red-400 bg-red-100 px-4 py-3 text-red-700"
          role="alert"
        >
          <span>Could not find any connected gateways 😭</span>
        </div>
      )}
    </div>
  );
};

export default GatewayList;
