import { FC, createContext, ReactNode, useState } from "react";

export interface Gateway {
  serial: string;
  name: string;
}

export interface GatewayContextType {
  gateway: Gateway | null;
  setGateway(gw: Gateway | null): void;
}

export const GatewayContext = createContext<GatewayContextType>({
  gateway: null,
  setGateway: () => {},
});

interface GatewayContextProviderProps {
  children: ReactNode;
}

export const GatewayContextProvider: FC<GatewayContextProviderProps> = ({
  children,
}) => {
  const [gateway, setGateway] = useState<Gateway | null>(null);

  return (
    <GatewayContext.Provider
      value={{
        gateway,
        setGateway,
      }}
    >
      {children}
    </GatewayContext.Provider>
  );
};
