import { toast } from "react-hot-toast";
import { IoCopyOutline } from "react-icons/io5";

// Define types for the ConnectedInfoBoxRow
type ConnectedInfoBoxRowProps = {
  label: string;
  value: string;
  displayValue?: string;
  valueColor?: string;
  isCopyable?: boolean;
};

const ConnectedInfoBoxRow: React.FC<ConnectedInfoBoxRowProps> = ({
  label,
  value,
  displayValue,
  valueColor,
  isCopyable,
}) => {
  // Shows displayValue if provided else defaults to value
  const displayText = displayValue || value;

  // Function to copy the value to the clipboard
  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(value);
      toast.success("Copied to clipboard");
      console.log("Copied to clipboard");
    } catch (err) {
      toast.error("Wops.. Something went wrong");
      console.error("Failed to copy: ", err);
    }
  };

  return (
    <div className="flex items-center justify-between rounded-md bg-gray-100 px-3 py-2">
      <span className="flex-1 overflow-hidden text-sm font-bold text-black">
        {label}:
      </span>
      <div className="flex flex-1 items-center justify-center overflow-hidden">
        <span
          className={`text-sm ${valueColor || "text-black"} text-overflow-ellipsis overflow-hidden whitespace-nowrap`}
          title={value}
          style={{ maxWidth: "calc(100% - 3rem)" }} // Reserve space for the button
        >
          {displayText}
        </span>
      </div>
      {isCopyable && (
        <button
          onClick={copyToClipboard}
          className="flex h-8 w-8 items-center justify-center rounded-md bg-gray-200 text-gray-600 hover:bg-gray-300"
        >
          <IoCopyOutline />
        </button>
      )}
    </div>
  );
};

export default ConnectedInfoBoxRow;
