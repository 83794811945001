import { FC, ReactNode } from "react";

interface ContentFrameworkProps {
  children?: ReactNode;
}

const ContentFramework: FC<ContentFrameworkProps> = ({ children }) => {
  return (
    <>
      <div className="flex flex-grow flex-col bg-[#1d1d1d] p-4 text-gray-200 md:mx-auto md:w-screen md:p-5 xl:flex-row dark:text-white">
        <main className="w-full flex-grow rounded shadow-sm lg:w-auto">
          {children}
        </main>
      </div>
    </>
  );
};

const LightFramework: FC<ContentFrameworkProps> = ({ children }) => {
  return <>{children}</>;
};

export { LightFramework };
export default ContentFramework;
