import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import { FC, useMemo } from "react";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { GatewayContextProvider } from "./context/GatewayContext";
import { BleProvider } from "./context/BleContext";
import { NavigationProvider } from "./context/NavigationContext";
import { Toaster } from "react-hot-toast";
import {
  RouterProvider,
  Router,
  Route,
  RootRoute,
} from "@tanstack/react-router";
import Location from "./pages/Location";
import CreateGw from "./pages/CreateGw";
import OnboardGW from "./pages/OnboardGW";
import ConnectGW from "./pages/ConnectGW";
import Index from "./pages/Index";
import PopupModule from "./components/layout/PopupModule";
import MainLayout from "./components/layout/MainLayout";

require("@solana/wallet-adapter-react-ui/styles.css");

const App: FC = () => {
  return <Context />;
};

// ROUTING
const rootRoute = new RootRoute({
  component: MainLayout,
});

const indexRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/",
  component: () => <Index />,
});
const locationRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/location",
  component: () => <Location />,
});
const createGwRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/create-gw",
  component: CreateGw,
});
const onboardGWRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/onboard-gw",
  component: OnboardGW,
});
const connectGWRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/connect-gw",
  component: ConnectGW,
});

const routeTree = rootRoute.addChildren([
  indexRoute,
  locationRoute,
  createGwRoute,
  onboardGWRoute,
  connectGWRoute,
]);
const router = new Router({ routeTree });

//QUERY
const queryClient = new QueryClient();

const Context: FC = () => {
  // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
  const network = WalletAdapterNetwork.Mainnet;

  // You can also provide a custom RPC endpoint.
  const endpoint = useMemo(() => clusterApiUrl(network), [network]);

  const wallets = useMemo(
    () => [
      /**
       * Wallets that implement either of these standards will be available automatically.
       *
       *   - Solana Mobile Stack Mobile Wallet Adapter Protocol
       *     (https://github.com/solana-mobile/mobile-wallet-adapter)
       *   - Solana Wallet Standard
       *     (https://github.com/solana-labs/wallet-standard)
       *
       * If you wish to support a wallet that supports neither of those standards,
       * instantiate its legacy wallet adapter here. Common legacy adapters can be found
       * in the npm package `@solana/wallet-adapter-wallets`.
       */
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [network],
  );

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>
          <QueryClientProvider client={queryClient}>
            <GatewayContextProvider>
              <BleProvider>
                <NavigationProvider>
                  <div className="flex flex-col md:h-screen">
                    <Toaster position="top-center" />
                    <PopupModule />
                    <RouterProvider router={router} />
                  </div>
                </NavigationProvider>
              </BleProvider>
            </GatewayContextProvider>
          </QueryClientProvider>
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
};

export default App;
