import { FC, useContext, useEffect, useState } from "react";
import { GatewayContext } from "../context/GatewayContext";
import { useQuery } from "@tanstack/react-query";
import { useWallet } from "@solana/wallet-adapter-react";
import toast from "react-hot-toast";
import { createMessageStr, signSrcfulMessage } from "../messaging/Message";
import { LightFramework } from "../components/ContentFramework";

const CreateGw: FC = () => {
  const [deviceSerial, setDeviceSerial] = useState("");
  const [devicePublicKey, setDevicePublicKey] = useState("");
  const { setGateway } = useContext(GatewayContext);
  useEffect(
    () => setGateway(null),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const { publicKey, signMessage } = useWallet();

  const { data, refetch, isFetching } = useQuery({
    queryKey: ["createGateway"],
    queryFn: async () => {
      if (!publicKey || !signMessage)
        throw new Error("Missing publicKey or signMessage");
      const toastId = toast.loading("Waiting for signature");
      try {
        const message = createMessageStr({
          wallet: publicKey.toString(),
          durationInSec: 60,
          messageData: {
            "Gateway Public-key": devicePublicKey,
            "Gateway Serial": deviceSerial,
          },
          Statement: "asdsdsd",
        });

        const signedMessage = await signSrcfulMessage(signMessage, message);

        if (!signedMessage) {
          toast.remove(toastId);
          return;
        }

        const query = JSON.stringify({
          query: `

                mutation($gatewayBasics: RegisterGateway!){
                    gatewayInception{
                        register(gatewayBasics: $gatewayBasics){
                        success
                        }
                    }
                }`,
          variables: {
            gatewayBasics: {
              message: signedMessage.message,
              signature: signedMessage.signature,
            },
          },
        });

        const requestHeaders: HeadersInit = new Headers();
        requestHeaders.set("Content-Type", "application/json");
        requestHeaders.set("Content-Length", query.length.toString());

        toast.loading("Sending data ...", {
          id: toastId,
        });

        const response = await fetch("https://api.srcful.dev/", {
          method: "POST",
          body: query,
          headers: requestHeaders,
        });
        toast.dismiss(toastId);
        return await response.json();
      } catch (error: any) {
        toast.dismiss(toastId);
        toast.error("Error:" + error.message);
      }
    },
    enabled: false,
  });

  useEffect(() => {
    if (!data) return;
    if (data.data.gatewayInception?.register?.success)
      toast.success("Successfully registered gateway");
    else toast.error("Failed to register gateway");
  }, [data]);

  return (
    <LightFramework>
      <div className="flex flex-grow flex-col items-center">
        <h1 className="mb-10 mt-10 font-srcful text-3xl sm:text-4xl">
          Create new Energy Gateway
        </h1>
        <form
          className="w-full rounded bg-gray-100 px-4 py-5 shadow-lg lg:w-1/2"
          onSubmit={(e) => {
            e.preventDefault();
            refetch();
          }}
        >
          <div className="mb-6 md:flex md:items-center">
            <div className="md:w-1/3">
              <label
                className="mb-1 block pr-4 font-bold text-gray-500 md:mb-0 md:text-right"
                htmlFor="inline-serial"
              >
                Serial
              </label>
            </div>
            <div className="md:w-2/3">
              <input
                onChange={(e) => setDeviceSerial(e.target.value)}
                className="w-full appearance-none rounded border-2 border-gray-300 bg-gray-50 px-4 py-2 leading-tight text-gray-700 placeholder-gray-300 focus:border-[#c80f77] focus:bg-white focus:placeholder-transparent focus:outline-none"
                id="inline-serial"
                type="text"
                placeholder="1Gs4...2fs"
              />
            </div>
          </div>
          <div className="mb-6 md:flex md:items-center">
            <div className="md:w-1/3">
              <label
                className="mb-1 block pr-4 font-bold text-gray-500 md:mb-0 md:text-right"
                htmlFor="inline-publickey"
              >
                Public Key
              </label>
            </div>
            <div className="md:w-2/3">
              <input
                onChange={(e) => setDevicePublicKey(e.target.value)}
                className="w-full appearance-none rounded border-2 border-gray-300 bg-gray-50 px-4 py-2 leading-tight text-gray-700 placeholder-gray-300 focus:border-[#c80f77] focus:bg-white focus:placeholder-transparent focus:outline-none"
                id="inline-publickey"
                type="text"
                placeholder="Kf7f..Hg2j"
              />
            </div>
          </div>
          <div className="md:flex md:items-center">
            <div className="md:w-1/3"></div>
            <div className="md:w-2/3">
              <button
                type="submit"
                className="focus:shadow-outline flex items-center justify-center rounded bg-[#c80f77] px-4 py-2 font-bold text-white shadow hover:bg-purple-400 focus:outline-none"
              >
                {isFetching && (
                  <svg
                    className="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      stroke-width="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                )}
                <span>{isFetching ? "Processing" : "Sign and create"}</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </LightFramework>
  );
};

export default CreateGw;
