// Cat
export enum Method {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE',
    PATCH = 'PATCH',
    UNKNOWN = 'UNKOWN',

}
export class MethodUtil {
    static fromString(string: string): Method {
        string = string.toUpperCase();
        switch (string) {
            case 'GET':
                return Method.GET;
            case 'POST':
                return Method.POST;
            case 'PUT':
                return Method.PUT;
            case 'DELETE':
                return Method.DELETE;
            case 'PATCH':
                return Method.PATCH;
            default:
                return Method.UNKNOWN;
        }
    }
}

