import React, { useState, useEffect } from "react";
import Modal from "./layout/Model";
import { BsInfoCircle } from "react-icons/bs";

import { useBle } from "../context/BleContext";
import * as ble from '../services/ble/';

type AccordionItem = {
  title: string;
  content: React.ReactElement<{
    updateCompletion: (isCompleted: boolean) => void;
  }>;
};

type AccordionProps = {
  items: AccordionItem[];
};

const Accordion: React.FC<AccordionProps> = ({ items }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [openItem, setOpenItem] = useState<number>(0);
  const [stepCompleted, setStepCompleted] = useState<boolean[]>(
    items.map(() => false),
  );
  const bleApi = useBle();

  useEffect(() => {
    const observerCallback = (response: ble.EGWTPResponse) => {
      if (response.location === ble.API_NAME) {
        setStepCompleted(items.map(() => true));
      }
    };

    bleApi.manager.subscribe(observerCallback);

    return () => {
      bleApi.manager.unsubscribe(observerCallback);
    };
  }, [bleApi, items]);

  const toggleItem = (index: number) => {
    if (stepCompleted[index] || index === openItem) {
      setOpenItem(openItem === index ? 0 : index);
    }
  };

  const handleNext = () => {
    if (
      openItem !== null &&
      openItem < items.length - 1 &&
      stepCompleted[openItem]
    ) {
      setOpenItem(openItem + 1);
    }
  };

  const handlePrevious = () => {
    if (openItem !== null && openItem > 0) {
      setOpenItem(openItem - 1);
    }
  };

  const openModalWithContent = (content: string) => {
    setModalContent(content);
    setIsModalOpen(true);
  };

  const updateStepCompletion = (index: number, isCompleted: boolean) => {
    const updatedCompletion = [...stepCompleted];
    updatedCompletion[index] = isCompleted;
    setStepCompleted(updatedCompletion);
  };

  const ModalText: { text: string }[] = [
    {
      text: "To link the energy gateway with the inverter and srcful servers, input the **SSID** (WiFi network name) and **Password**. It's crucial to ensure the entries are accurate and case-sensitive for a secure connection.",
    },
    {
      text: "Provide your **Solana wallet address** for reward registration. This step is vital for participating in reward schemes. Double-check the address accuracy, as any rewards will be sent directly to this wallet. **Important**: Double-check the wallet address, as rewards sent to an incorrect address cannot be retrieved or compensated.",
    },
    {
      text: "Enter the necessary information to connect to your inverter, including **IP address, port, device address, and inverter model**. This will establish a Modbus TCP/IP connection, enabling data monitoring (for PoS) and control (for PoC) of your inverter. Ensure all details are correct for successful integration.",
    },
    {
      text: "Set the exact loation of your solar panels. This is important for data validation and energy services. The exact position is only stored interally and only the hex position is shared publicly.",
    },
  ];

  return (
    <div className="flex w-full flex-col xl:w-[600px]">
      {items.map((item, index) => (
        <div key={index} className="border-b border-gray-100/20">
          <div className="flex">
            <button
              onClick={() => toggleItem(index)}
              className="w-full py-3 text-left text-xl focus:outline-none"
            >
              {item.title}
            </button>
            <button onClick={() => openModalWithContent(ModalText[index].text)}>
              <BsInfoCircle size={20} />
            </button>
            <Modal
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              content={modalContent}
            />
          </div>
          <div
            className="overflow-hidden transition-all duration-300 ease-in-out"
            style={{
              maxHeight: openItem === index ? "1000px" : "0px",
            }}
          >
            <div>
              {React.cloneElement(item.content, {
                updateCompletion: (isCompleted: boolean) =>
                  updateStepCompletion(index, isCompleted),
              })}
            </div>
          </div>
        </div>
      ))}
      <div className="mt-4 flex gap-2">
        {openItem > 0 && (
          <button
            onClick={handlePrevious}
            className="rounded bg-[#0be898] px-4 py-2 text-black hover:bg-[#06d388] disabled:bg-gray-500 disabled:opacity-50"
          >
            Previous
          </button>
        )}
        {openItem < items.length - 1 && (
        <button
          onClick={handleNext}
          className="rounded bg-[#0be898] px-4 py-2 text-black hover:bg-[#06d388] disabled:bg-gray-500 disabled:opacity-50"
          disabled={openItem === items.length - 1 || !stepCompleted[openItem]}
        >
          Next
        </button>
        )}
      </div>
    </div>
  );
};

export default Accordion;
